<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('damReport.grow_avg_price_report_dam') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Division" vid="division_id">
                          <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="division_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('globalTrans.division') }}
                          </template>
                          <b-form-select
                          id="division_id"
                          plain
                          v-model="formData.division_id"
                          :options="divisionList"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Commodity Group" vid="com_grp_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="com_grp_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_group') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.com_grp_id"
                              :options="commodityGroupList"
                              id="com_grp_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Commodity Sub Group" vid="com_subgrp_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="com_grp_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_sub_group') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.com_subgrp_id"
                              :options="commoditySubGroupList"
                              id="com_subgrp_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Commodity Name" vid="commodity_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="commodity_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.commodity_id"
                              :options="commodityNameList"
                              id="commodity_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Date" vid="price_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="price_date"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('globalTrans.from_date')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-input
                                class="datepicker"
                                v-model="formData.start_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('crop_price_info.date_figure')"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Date" vid="price_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="price_date"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('globalTrans.to_date')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-input
                                class="datepicker"
                                v-model="formData.end_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('crop_price_info.date_figure')"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                        </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay :show="loading">
              <iq-card v-if='datas.length'>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('damReport.grow_avg_price_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                            <!-- :title="$t('damReport.grow_avg_price_report')" -->
                          <export-excel
                            class="btn btn_add_new"
                            :data="excelData"
                            :title="headerValue"
                            worksheet="Report Sheet"
                            :default-value="headerExcelDefault"
                            name="grower-average-price-report.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="warehouseServiceBaseUrl" uri="/master-warehouse-report-heading/detail" :org-id="13">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('damReport.grow_avg_price_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <table style="width:100%;color:black;">
                                          <tr>
                                            <td align="right" style="width:20%">{{ $t('crop_price_config.commodity_group') }}</td>
                                            <td align="center" style="width:2%">:</td>
                                            <td align="left" style="width:10%">{{ ($i18n.locale==='bn') ? formData.commodity_group_name_bn : formData.commodity_group_name_en }}</td>
                                            <td align="right" style="width:20%">{{ $t('crop_price_config.commodity_sub_group') }}</td>
                                            <td align="center" style="width:2%">:</td>
                                            <td align="left" style="width:10%">{{ ($i18n.locale==='bn') ? formData.commodity_sub_group_name_bn : formData.commodity_sub_group_name_en }}</td>
                                            <td align="right" style="width:15%">{{ $t('crop_price_config.commodity_name') }}</td>
                                            <td align="center" style="width:2%">:</td>
                                            <td align="left" style="width:10%">{{ ($i18n.locale==='bn') ? formData.commodity_name_bn : formData.commodity_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td align="right" style="width:20%">{{ $t('globalTrans.from_date') }}</td>
                                            <td align="center" style="width:2%">:</td>
                                            <td align="left" style="width:10%">{{ formData.start_date | dateFormat }}</td>
                                            <td align="right" style="width:20%">{{ $t('globalTrans.to_date') }}</td>
                                            <td align="center" style="width:2%">:</td>
                                            <td align="left" style="width:10%">{{ formData.end_date | dateFormat }}</td>
                                          </tr>
                                        </table>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <!-- <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                                        <b-thead>
                                          <b-tr>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.product_name_and_description') }}</b-th>
                                            <b-th style="width:20%" class="text-center">{{ $t('damReport.measurement') }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('damReport.average_wholesale_price') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in datas" :key="index">
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.commodity.commodity_name_bn : info.commodity.commodity_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.commodity.unit_retail.unit_name_bn : info.commodity.unit_retail.unit_name }} </b-td>
                                          <b-td class="text-center" >{{ $n(parseFloat(info.avg_wholesale_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(info.avg_wholesale_highestPrice).toFixed(2)) }}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row> -->
                                  <b-row>
                                        <b-col sm="12" class="steaky-header">
                                            <slot>
                                                <b-table head-variant="primary" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="datas" :fields="columns">
                                                  <template v-slot:cell(avg_wholesale_lowestPrice)="data">
                                                    {{ $n(parseFloat(data.item.avg_wholesale_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.avg_wholesale_highestPrice)) }}
                                                  </template>
                                                </b-table>
                                            </slot>
                                        </b-col>
                                    </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { growerAvgPriceReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import { dateFormat } from '@/Utils/fliter'
import Store from '@/store'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead
    },
    created () {
        if (this.id) {
            const tmp = this.getEditingData()
            this.formData = tmp
        }
        if (this.$store.state.Auth.activeRoleId !== 1) {
          this.formData = Object.assign({}, this.formData, {
              divisionList: JSON.stringify(this.loggedUserPrivilege.division_id),
              districtList: JSON.stringify(this.loggedUserPrivilege.district_id),
              upazillaList: JSON.stringify(this.loggedUserPrivilege.upazila_id),
              marketList: JSON.stringify(this.loggedUserPrivilege.market_id)
          })
        }
    },
    data () {
        return {
          AveragePriceText: '',
          maxPriceMsg: '',
          minPriceMsg: '',
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          formData: {
            division_id: '0',
            district_id: '0',
            upazila_id: '0',
            market_id: '0',
            year: 0,
            month_id: 0,
            week_id: 0,
            price_date: '',
            com_grp_id: '0',
            com_subgrp_id: '0',
            commodity_id: '0',
            start_date: '',
            end_date: '',
            commodity_name_en: '',
            commodity_name_bn: '',
            commodity_group_name_en: '',
            commodity_group_name_bn: '',
            commodity_sub_group_name_bn: '',
            commodity_sub_group_name_en: '',
            divisionList: [],
            districtList: [],
            upazillaList: [],
            marketList: []
          },
          currentDate: '',
          listItemofyerterday: '',
          districtList: [],
          upazilaList: [],
          marketList: [],
          commoditySubGroupList: [],
          warehouseServiceBaseUrl: warehouseServiceBaseUrl,
          datas: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          showData: false,
          commodityNameList: []
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
         this.headerDataExcel()
        this.AveragePriceText = (parseInt(this.formData.HighestPrice) + parseInt(this.formData.LowestPrice)) / 2
    },
    computed: {
        headerValue: function () {
          const headerVal = []
          if (this.$i18n.locale === 'en') {
              headerVal[0] = this.headerExcelDefault.orgName
              headerVal[1] = this.headerExcelDefault.address
              headerVal[2] = this.$t('damReport.grow_avg_price_report')
              headerVal[3] = this.$t('crop_price_config.commodity_group') + ':  ' + this.formData.commodity_group_name_en + '; ' + ' ' + this.$t('crop_price_config.commodity_sub_group') + ': ' + this.formData.commodity_sub_group_name_en + '; ' + ' ' + this.$t('crop_price_config.commodity_name') + ' : ' + this.formData.commodity_name_en
              headerVal[4] = this.$t('globalTrans.from_date') + ':  ' + this.formData.start_date + '; ' + ' ' + this.$t('globalTrans.to_date') + ': ' + this.formData.end_date
            } else {
              headerVal[0] = this.headerExcelDefault.orgNameBn
              headerVal[1] = this.headerExcelDefault.address_bn
              headerVal[2] = this.$t('damReport.grow_avg_price_report')
              headerVal[3] = this.$t('crop_price_config.commodity_group') + ':  ' + this.formData.commodity_group_name_bn + '; ' + ' ' + this.$t('crop_price_config.commodity_sub_group') + ': ' + this.formData.commodity_sub_group_name_bn + '; ' + ' ' + this.$t('crop_price_config.commodity_name') + ' : ' + this.formData.commodity_name_bn
              headerVal[4] = this.$t('globalTrans.from_date') + ':  ' + dateFormat(this.formData.start_date) + '; ' + ' ' + this.$t('globalTrans.to_date') + ': ' + dateFormat(this.formData.end_date)
            }
            return headerVal
          },
        excelData: function () {
          const listData = this.datas
          var serial = 0
          const listContractor = listData.map(keyItem => {
            serial++
             if (this.$i18n.locale === 'en') {
                return {
                  'sl ': serial,
                  'Product name and description': keyItem.commodity_name,
                  'Measurement ': keyItem.w_unit_name,
                  'Average Wholesale Price': parseFloat(keyItem.avg_wholesale_lowestPrice).toFixed(2) + '--' + parseFloat(keyItem.avg_wholesale_highestPrice).toFixed(2)
                }
              } else {
                return {
                  'ক্রমিক সংখ্যা': this.$n(serial),
                  'পণ্যের নাম ও বিবরণ': keyItem.commodity_name_bn,
                  'একক ': keyItem.w_unit_name_bn,
                  'গড় পাইকারী দর': this.$n(parseFloat(keyItem.avg_wholesale_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(keyItem.avg_wholesale_highestPrice).toFixed(2))
                }
              }
          })
          return listContractor
        },
        commodityGroupList: function () {
        const commodityGroupList = this.$store.state.agriMarketing.commonObj.commodityGroupList
        return commodityGroupList.map(e => {
          if (this.$i18n.locale === 'bn') {
            return { value: e.value, text: e.text_bn }
          } else {
            return { value: e.value, text: e.text_en }
           }
         })
        },
        yearList: function () {
            return this.$store.state.agriMarketing.commonObj.yearList
        },
        monthList: function () {
            return this.$store.state.commonObj.monthList
        },
        weekList: function () {
          return this.$store.state.agriMarketing.commonObj.weekList
        },
        divisionList: function () {
          return this.$store.state.agriMarketing.damData.divisionList
        },
        priceEntryTypeList: function () {
          return this.$store.state.agriMarketing.commonObj.priceEntryTypeList
        },
        timePreiodList: function () {
          return this.$store.state.agriMarketing.commonObj.timePreiodList
        },
        i18 () {
          return this.$i18n.locale
        },
        priceTypeList: function () {
          return this.$store.state.agriMarketing.commonObj.priceTypeList
        },
        columns () {
          const labels = [
              { label: this.$t('damReport.product_name_and_description'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.average_grower_price'), class: 'text-center', sortable: true }
            ]
          let keys = []

          if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'commodity_name_bn' },
              { key: 'w_unit_name_bn' },
              { key: 'avg_wholesale_lowestPrice' }
            ]
          } else {
            keys = [
              { key: 'commodity_name' },
              { key: 'w_unit_name' },
              { key: 'avg_wholesale_lowestPrice' }
            ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
        },
        loading () {
          return this.$store.state.commonObj.loading
        },
        loggedUserPrivilege: function () {
          return this.$store.state.agriMarketing.commonObj.loggedUserPrivilege
        }
    },
    watch: {
        'formData.com_grp_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.commoditySubGroupList = this.getCommoditySubGroupList(newVal)
        }
      },
      'formData.com_subgrp_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.commodityNameList = this.getCommodityNameList(newVal)
        }
      }
    },
    methods: {
      headerDataExcel () {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
        },
      getCommoditySubGroupList (commodityGroupId) {
        const commoditySubGroupList = this.$store.state.agriMarketing.commonObj.commoditySubGroupList.filter(item => item.status === 1 && item.commodity_group_id === commodityGroupId)
        return commoditySubGroupList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text }
          }
        })
      },
      getCommodityNameList (commoditySubGroupId) {
        const commodityNameList = this.$store.state.agriMarketing.commonObj.commodityNameList.filter(item => item.status === 1 && item.commodity_sub_group_id === commoditySubGroupId)
        return commodityNameList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text }
          }
        })
      },
      pdfExport () {
          const reportTitle = this.$t('damReport.grow_avg_price_report')
          ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.datas, this, this.formData)
      },
      onChangeFile (e) {
          this.attachmentDemo = e.target.files[0]
      },
      getEditingData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      },
      async searchData () {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      this.showData = true
      const commoditySubGroupList = this.$store.state.agriMarketing.commonObj.commoditySubGroupList
      const commodityGroupLists = this.$store.state.agriMarketing.commonObj.commodityGroupList
      const commodityNameList = this.$store.state.agriMarketing.commonObj.commodityNameList
      const commodityGroup = commodityGroupLists.find(item => item.value === this.formData.com_grp_id)
      this.formData.commodity_group_name_en = typeof commodityGroup !== 'undefined' ? commodityGroup.text_en : ''
      this.formData.commodity_group_name_bn = typeof commodityGroup !== 'undefined' ? commodityGroup.text_bn : ''
      const commoditySubGroup = commoditySubGroupList.find(item => item.value === this.formData.com_subgrp_id)
      this.formData.commodity_sub_group_name_en = typeof commoditySubGroup !== 'undefined' ? commoditySubGroup.text_en : ''
      this.formData.commodity_sub_group_name_bn = typeof commoditySubGroup !== 'undefined' ? commoditySubGroup.text_bn : ''
      const commodityName = commodityNameList.find(item => item.commodity_sub_group_id === this.formData.com_subgrp_id)
      this.formData.commodity_name_en = typeof commodityName !== 'undefined' ? commodityName.text_en : ''
      this.formData.commodity_name_bn = typeof commodityName !== 'undefined' ? commodityName.text_bn : ''
        const divisionObj = this.divisionList.find(item => item.value === this.formData.division_id)
        this.formData.division_name_en = typeof divisionObj !== 'undefined' ? divisionObj.text_en : ''
        this.formData.division_name_bn = typeof divisionObj !== 'undefined' ? divisionObj.text_bn : ''
        // const params = Object.assign({}, { start_date: this.formData.start_date, end_date: this.formData.end_date, division_id: this.formData.division_id, commodity_id: this.formData.commodity_id })
        await RestApi.getData(agriMarketingServiceBaseUrl, growerAvgPriceReport, this.formData).then(response => {
          if (response.success) {
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            if (response.data) {
              this.datas = this.getRelationalData(response.data)
            }
          } else {
            this.datas = []
            Store.commit('mutateCommonProperties', {
              loading: false
            })
          }
        })
      },
        getRelationalData (data) {
          const list = data.map(item => {
            const commodityObj = this.$store.state.agriMarketing.commonObj.commodityNameList.find(commodity => commodity.value === item.commodity_id)
            const wUnitObj = this.$store.state.agriMarketing.commonObj.measurementUnitList.find(unit => unit.value === item.unit_whole_sale)
            const commodityData = {
              commodity_name: typeof commodityObj !== 'undefined' ? commodityObj.text_en : '',
              commodity_name_bn: typeof commodityObj !== 'undefined' ? commodityObj.text_bn : ''
            }
            const wUnitData = {
              w_unit_name: wUnitObj !== 'undefined' ? wUnitObj.text_en : '',
              w_unit_name_bn: wUnitObj !== 'undefined' ? wUnitObj.text_bn : ''
            }
            return Object.assign({}, item, wUnitData, commodityData)
          })
          return list
        },
      getDistrictList (divisionId = null) {
          return this.$store.state.agriMarketing.damData.districtList.filter(item => item.division_id === divisionId)
      },
      getUpazilaList (districtId = null) {
          const upazilaList = this.$store.state.agriMarketing.damData.upazilaList
          if (districtId) {
              return upazilaList.filter(upazila => upazila.district_id === districtId)
          }
          return upazilaList
      },
      getMarketList (upazillaId = null) {
          const dataList = this.$store.state.agriMarketing.commonObj.marketList.filter(item => item.upazila_id === upazillaId)
          return dataList.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text }
              }
          })
      },
      getPriceTypeList: function (entryType) {
          const dataList = this.$store.state.agriMarketing.commonObj.priceTypeList.filter(item => item.entry_type === entryType)
          return dataList.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text }
              }
          })
      }
    }
}
</script>
